<template>
  <div class="bee-bus-annual-pass-loan">

    <b-row class="d-flex align-items-center mb-3">
      <b-col cols="12" md="4">
        <b-img fluid rounded src="/misc/bee-network-annual-ticket.jpg" alt="Bee Network Annual Pass" class="mb-3 mb-md-0" />
      </b-col>
      <b-col cols="12" md="8">
        <h1>Annual Bee Network Ticket Loan</h1>
        <h3>From Sunday, 23rd March 2025</h3>

        <p class="mt-3 lead" >The Annual Bee Network Ticket has been extended!</p>
        <p>Annual Bee Network Tickets will allow unlimited travel on any Bee Network Bus or Tram in the zones purchased, for one year.</p>
      </b-col>
    </b-row>

    <b-row class="d-flex align-items-center mb-3">
      <b-col cols="12" md="8" order="1" order-md="0">
        <h1>Annual Bee Network Ticket Loan</h1>
        <p class="lead">Save up to £272 by spreading the cost of your Annual Bee Ticket Pass. Pay for your pass over 52 weeks,
          or 12 monthly repayments, at no extra cost.</p>
        <ul class="list-unstyled key-points">
          <li>
            <b-icon-piggy-bank-fill font-scale="2"  variant="success"/>
            <div>
              <h5 class="text-success">Save up to £272</h5>
            </div>
          </li>
          <li>
            <b-icon-hand-thumbs-up-fill font-scale="2" variant="success"/>
            <div>
              <h5 class="text-success">No added costs or charges</h5>
            </div>
          </li>
          <li>
            <b-icon-check-circle-fill font-scale="2" variant="success"/>
            <div>
              <h5 class="text-success">No credit check</h5>
              <div>Affordability and eligibility checks apply</div>
            </div>
          </li>
        </ul>
     </b-col>
      <b-col cols="12" md="4" order="0" order-md="1">
        <b-img fluid rounded src="/misc/bee-bus-network.jpg" alt="Bee Network" class="mb-3 mb-md-0" />
      </b-col>
    </b-row>
    <hr/>

    <h2>Metrolink find your tram zone</h2>
    <p>Use the links provided below to identify the zonal ticket required then buy your ticket from your local credit union.</p>
    <p>
      <b-link href="https://tfgm.com/tickets-and-passes/fare-zones/tram" target="_blank">Metrolink Fare Zones | Bee Network | Powered by TfGM</b-link>
    </p>
    <p>
      <b-link href="https://tfgm.com/tickets-and-passes/fare-zones/tram/find-your-tram-zone" target="_blank">Find your tram zone | Bee Network | Powered by TfGM</b-link>
    </p>
    <hr/>

    <h2>Save Big on Unlimited Bus Travel with the Annual Bee Network Ticket</h2>
    <p class="lead">
      Imagine a whole year of unlimited travel across Greater Manchester and beyond, without the upfront cost!</p>
    <p>Cash Box Credit Union makes it easy with our flexible repayment plan – no fees, no hidden charges, just a smarter way to <strong>save on your
      Bee Network Ticket</strong>. Travel within Greater Manchester or even Bee Network buses crossing the boundary, right up until the last service of the ticket’s expiry date (or 4 am for 24-hour buses).
    </p>
    <h5 class="mb-4">Start your journey to stress-free travel and big savings today!</h5>

    <div class="d-flex flex-column flex-md-row" style="gap:0.5rem;">
      <a href="https://cashbox.cuaccount.com/login/" target="_blank" class="btn btn-primary">Existing Members - Apply Here</a>
      <a href="https://cashbox.cuaccount.com/join/" target="_blank" class="btn btn-success">New Members</a>
    </div>

    <hr/>
    <h2>How does it work?</h2>
    <ol>
      <li>Apply for the Bee Network Pass</li>
      <li>Acceptance is subject to eligibility and affordability</li>
      <li>If accepted, you make the first repayment immediately</li>
      <li>Once the first repayment is paid, Transport for Greater Manchester will contact you within 3 working days with instructions on how to collect your Pass from your nearest Bee Network Information and Ticket Office.</li>
      <li><strong>Cash Box Credit Union</strong> will assist in setting up a standing order to make the rest of your weekly or monthly repayments.</li>
    </ol>
    <p>
      To be accepted for the repayment plan you must become a member of <strong>Cash Box Credit Union</strong>. This will form part of your application.
      If accepted, we will provide information on how you can save with <strong>Cash Box Credit Union</strong> as well as making your repayments.
    </p>

    <div class="d-flex flex-column flex-md-row" style="gap:0.5rem;">
      <a href="https://cashbox.cuaccount.com/login/" target="_blank" class="btn btn-primary">Existing Members - Apply Here</a>
      <a href="https://cashbox.cuaccount.com/join/" target="_blank" class="btn btn-success">New Members</a>
    </div>

    <hr/>

    <h2>Bee Network Tickets</h2>
    <div v-for="(table, tableIndex) in tables" :key="tableIndex">
      <h3>{{ table.description }}</h3>
      <b-table
          outlined
          thead-class="bg-success text-white"
          class="mt-3 mb-0"
          :items="table.items"
          :fields="table.fields"
          responsive
          stacked="sm"
      />
      <p><small>{{ table.footer }}</small></p>
    </div>

    <h2>Frequently Asked Questions</h2>
    <div class="mb-4 mt-4 d-flex flex-column" style="gap:1rem;">
      <b-card no-body>
        <b-card-header>
          <h5 class="m-0">Can I get the weekly or monthly repayment plan directly from TfGM?</h5>
        </b-card-header>
        <b-card-body>
          <b-card-text> No, this is only available from participating credit unions. You can pay for the Pass in full via the TfGM website.</b-card-text>
          <b-card-text><b-link href="https://tfgm.com/tickets-and-passes/annual-bee-bus-ticket" target="_blank">Visit TfGM for more information</b-link></b-card-text>
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h5 class="m-0">How soon will I be able to use my Pass?</h5>
        </b-card-header>
        <b-card-body>
          <b-card-text>Once the first repayment is made we will ask TfGM to issue the Pass and contact you to arrange pick up. The ticket will be activated and available for use within 7 days of collection.</b-card-text>
          <b-card-text>The Bee Network Ticket Pass can be used from January 5th 2025</b-card-text>
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h5 class="m-0">How will I collect my Pass?</h5>
        </b-card-header>
        <b-card-body>
          <b-card-text>TfGM will contact you with details of where to collect your pass form your nearest Information and Ticket Office. You will need to take some ID and a copy of your repayment agreement or agreement number as evidence when you collect your Pass.</b-card-text>
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h5 class="m-0">What if I stop making the repayments?</h5>
        </b-card-header>
        <b-card-body>
          <b-card-text>If you stop making repayments, the Pass will no longer work. If you find you are struggling to make a repayment, you should contact <strong>Cash Box Credit Union</strong> immediately to see if there is anything we can do to help.</b-card-text>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {formatToCurrency, formatToPercentage} from "@/data/utils-number";

export default {
  name: 'BeeBussAnnualPassLoan',
  metaInfo() {
    return {
      title: 'Bee Network Annual Ticket Loan | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "Credit, Union, Sound, Pound, SoundPound, Loan, Greater Manchester, Bee, Bee Network, Credit Union, Credit Unions" },
        { name: 'description', content: "Annual Ticket Loan, save up to £272 a year on your Bee Network Ticket." }
      ],
    };
  },
  data() {
    return {
      tables: [
        {
          description: "Annual Bee Bus Ticket",
          footer: "The product pricing equates a saving of £240 or 23.1% compared to the cost of paying monthly.",
          fields: [
            {key: "product", label: "Product"},
            {key: "price", label: "Ticket Price", formatter: (value) => formatToCurrency(value) },
            {key: "saving", label: "Saving", formatter: (value) => formatToPercentage(value) },
          ],
          items: [
            { product: "Annual Bee Ticket - Bus Only", price: 800, saving: 23.1 },
          ]
        },
        {
          description: "Annual Bee Tram Ticket",
          footer: "The product pricing equates a saving of 15.4% compared to the cost 13 x 28-day tickets (equivalent to 56 days of free travel)",
          fields: [
            {key: "product", label: "Product"},
            {key: "price", label: "Ticket Price", formatter: (value) => formatToCurrency(value) },
            {key: "saving", label: "Saving", formatter: (value) => formatToPercentage(value) },
          ],
          items: [
            { product: "Tram Only - Single Zone", price: 416, saving: 15.4 },
            { product: "Tram Only - Zone 1 + 2", price: 676, saving: 15.4 },
            { product: "Tram Only - Zone 2 + 3", price: 592, saving: 15.4 },
            { product: "Tram Only - Zone 3 + 4", price: 592, saving: 15.4 },
            { product: "Tram Only - Zone 2 + 3 + 4", price: 769, saving: 15.4 },
            { product: "Tram Only - Zone 1 + 2 + 3", price: 967, saving: 15.4 },
            { product: "Tram Only - All Zones", price: 1154, saving: 15.4 },
          ]
        },
        {
          description: "Annual Bee Bus and Tram Ticket",
          footer: "The product pricing equates a saving of 15.4% compared to the cost 13 x 28-day tickets (equivalent to 56 days of free travel)",
          fields: [
            {key: "product", label: "Product"},
            {key: "price", label: "Ticket Price", formatter: (value) => formatToCurrency(value) },
            {key: "saving", label: "Saving", formatter: (value) => formatToPercentage(value) },
          ],
          items: [
            { product: "Bee Bus and Tram Zones 1 + 2", price: 1211, saving: 15.4 },
            { product: "Bee Bus and Tram Zones 2 + 3 or 3 + 4", price: 1140, saving: 15.4 },
            { product: "Bee Bus and Tram Zones 2 + 3 + 4", price: 1293, saving: 15.4 },
            { product: "Bee Bus and Tram Zones 1 + 2 + 3", price: 1418, saving: 15.4 },
            { product: "Bee Bus and all Tram Zones 1 + 2 + 3 + 4", price: 1496, saving: 15.4 },
          ]
        }
      ]
    };
  }
}
</script>

<style>

.bee-bus-annual-pass-loan .key-points {
  display: flex;
  flex-direction: column;
  gap:1rem;
}

.bee-bus-annual-pass-loan .key-points li {
  display: flex;
  align-items:center;
  gap:1rem;
}

.bee-bus-annual-pass-loan .key-points li h5 {
  margin:0;
  font-weight: bold;
}

.modal-body.bee-bus-annual-pass-modal-body {
  padding: 0.5rem 0;
}

.modal-body.bee-bus-annual-pass-modal-body .bee-bus-annual-pass-modal-iframe {
  height: 800px;
}

@media (max-width: 1199px) {
  .modal-body.bee-bus-annual-pass-modal-body .bee-bus-annual-pass-modal-iframe {
    height: 1050px;
  }
}

@media (max-width: 991px) {
  .modal-body.bee-bus-annual-pass-modal-body .bee-bus-annual-pass-modal-iframe {
    height: 1150px;
  }
}

@media (max-width: 767px) {
  .modal-body.bee-bus-annual-pass-modal-body .bee-bus-annual-pass-modal-iframe {
    height: 1250px;
  }
}

@media (max-width: 575px) {
  .modal-body.bee-bus-annual-pass-modal-body .bee-bus-annual-pass-modal-iframe {
    height: 1350px;
  }
}

</style>
